<template>
  <div>
    <h1 class="mr-sm-4 header-tablepage mb-3">Option system</h1>
    <b-tabs ref="b_tabs">
      <b-tab title="General">
        <div class="title-tabs">Coupon Use By Customer</div>
        <div class="bg-white p-2 p-lg-3">
          <div class="row">
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Invoice No. for Customer Use Coupon"
                name="invoice_no"
                isRequired
                placeholder=""
                type="text"
                :v="$v.form.invoice_no_customer_coupon"
                :isValidate="$v.form.invoice_no_customer_coupon.$error"
                v-model="form.invoice_no_customer_coupon"
                @onKeypress="checkText"
              />
            </div>

            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Invoice No for Customer User coupon
              </div>
              <div class="text-black">
                Invoice No when customer use coupon from customer side.
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <InputText
                textFloat="Customer User Product (Article No.)"
                name="article_no"
                isRequired
                placeholder=""
                type="text"
                :v="$v.form.customer_user_coupon_product"
                :isValidate="$v.form.customer_user_coupon_product.$error"
                v-model="form.customer_user_coupon_product"
                @onKeypress="checkText"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Customer User Product (Article No.)
              </div>
              <div class="text-black">
                This product will apply automatically in transaction when
                customer use coupon from customer side.
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <InputText
                textFloat="Customer Use Coupon Sale (Sale ID.)"
                name="sale_id"
                isRequired
                placeholder=""
                type="text"
                :v="$v.form.customer_user_coupon_sale"
                :isValidate="$v.form.customer_user_coupon_sale.$error"
                v-model="form.customer_user_coupon_sale"
                @onKeypress="checkText"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Customer User Coupon Sales (Sale ID)
              </div>
              <div class="text-black">
                This sale will apply automatically in transaction when customer
                user coupon from customer side.
              </div>
            </div>
          </div>
        </div>
        <div class="title-tabs">Customer Life Cycle</div>
        <div class="bg-white p-2 p-lg-3">
          <div class="row">
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Prospect to inactive Prospect (Day)"
                name="x_day_prospect_inactive"
                placeholder=""
                :v="$v.form.x_day_prospect_inactive"
                :isValidate="$v.form.x_day_prospect_inactive.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_prospect_inactive"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Prospect to inactive Prospect (Day)
              </div>
              <div class="text-black">
                a prospect customer will be designated as inactive prospect if
                they do note complete a transaction within a specified number of
                days since becoming a prospect.
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="First Purchase Customer to Inactive Customer (Day)"
                name="x_day_first_lapse"
                placeholder=""
                :v="$v.form.x_day_first_lapse"
                :isValidate="$v.form.x_day_first_lapse.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_first_lapse"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                First Purchase customer to Inactive Customer (Day)
              </div>
              <div class="text-black">
                a first purchase customer will be designated as inactive
                customer if they do not complete a transaction within a
                specified number of days since becoming a first purchase
                customer.
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Active Customer to Lapse Customer (Day)"
                name="x_day_active_lapse"
                placeholder=""
                type="number"
                :v="$v.form.x_day_active_lapse"
                :isValidate="$v.form.x_day_active_lapse.$error"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_active_lapse"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Active customer to Lapse customer (Day)
              </div>
              <div class="text-black">
                a active customer will be designated as Lapse if they do not
                complete a transaction within a specified number of days since
                becoming a active customer.
              </div>
            </div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Lapse Customer to Inactive Customer (Day)"
                name="x_day_lapse_inactive"
                placeholder=""
                :v="$v.form.x_day_lapse_inactive"
                :isValidate="$v.form.x_day_lapse_inactive.$error"
                type="number"
                oninput="if( this.value.length > 3 )  this.value = this.value.slice(0,3)"
                isRequired
                v-model="form.x_day_lapse_inactive"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">
                Lapse customer to Inactive customer (Day)
              </div>
              <div class="text-black">
                a Lapse customer will be designated as inactive prospect if they
                do note complete a transaction within a specified number of days
                since becoming a Lapse customer.
              </div>
            </div>
          </div>
        </div>

        <div class="title-tabs">Privacy Policy</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="custom-link"
                id="custom-link"
                @change="clearField($event, ['consent_url_link'])"
                v-model="form.is_custom_privacy_link"
                value="1"
                unchecked-value="0"
              >
                Custom Privacy Policy Link
              </b-form-checkbox>
            </b-col>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Privacy Policy Link"
                name="custom-link"
                :placeholder="
                  form.is_custom_privacy_link == 0
                    ? ''
                    : 'Please Insert Link (example : https://www.example.com)'
                "
                :v="$v.form.consent_url_link"
                :isValidate="$v.form.consent_url_link.$error"
                type="text"
                :disabled="form.is_custom_privacy_link == 0"
                v-model="$v.form.consent_url_link.$model"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
          </div>
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="custom-term-link"
                id="custom-term-link"
                @change="clearField($event, ['term_and_con_url'])"
                v-model="form.is_term_and_con_url"
                value="1"
                unchecked-value="0"
              >
                Custom Terms and Conditions Link
              </b-form-checkbox>
            </b-col>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Terms and Conditions Link"
                name="custom-term-link"
                :placeholder="
                  form.is_term_and_con_url == 0
                    ? ''
                    : 'Please Insert Link (example : https://www.example.com)'
                "
                :v="$v.form.term_and_con_url"
                :isValidate="$v.form.term_and_con_url.$error"
                type="text"
                :disabled="form.is_term_and_con_url == 0"
                v-model="$v.form.term_and_con_url.$model"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
          </div>
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="custom-right-link"
                id="custom-right-link"
                @change="clearField($event, ['right_request_url'])"
                v-model="form.is_right_request_url"
                value="1"
                unchecked-value="0"
              >
                Custom Right Request Link
              </b-form-checkbox>
            </b-col>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Right Request Link"
                name="custom-right-link"
                :placeholder="
                  form.is_right_request_url == 0
                    ? ''
                    : 'Please Insert Link (example : https://www.example.com)'
                "
                :v="$v.form.right_request_url"
                :isValidate="$v.form.right_request_url.$error"
                type="text"
                :disabled="form.is_right_request_url == 0"
                v-model="$v.form.right_request_url.$model"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
          </div>
        </div>
        <div class="title-tabs">Point System</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio
              v-model="form.point_system"
              :aria-describedby="ariaDescribedby"
              name="point-selected"
              value="1"
              >Center Point</b-form-radio
            >
            <b-form-radio
              v-model="form.point_system"
              :aria-describedby="ariaDescribedby"
              name="point-selected"
              value="0"
              >Branch Point</b-form-radio
            >
          </b-form-group>
        </div>
        <div class="title-tabs">Single Sign on (SSO)</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col cols="12">
              <b-form-checkbox
                class="my-2"
                name="single-sign-on"
                id="single-sign-on"
                v-model="form.enable_single_sign_on_azure_active_directory"
                value="1"
                unchecked-value="0"
              >
                Enable Single Sign on Azure Active Directory
              </b-form-checkbox>
            </b-col>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Client ID"
                name="client-id"
                placeholder="Client ID"
                :v="$v.form.client_id"
                :isRequired="
                  form.enable_single_sign_on_azure_active_directory == 1
                "
                :isValidate="$v.form.client_id.$error"
                type="text"
                :disabled="
                  form.enable_single_sign_on_azure_active_directory == 0
                "
                v-model="$v.form.client_id.$model"
              />
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0"></div>
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Endpoint"
                name="endpoint"
                :placeholder="
                  form.enable_single_sign_on_azure_active_directory == 0
                    ? ''
                    : 'Please Insert Link (example : https://www.example.com)'
                "
                :isRequired="
                  form.enable_single_sign_on_azure_active_directory == 1
                "
                :v="$v.form.azure_endpoint"
                :isValidate="$v.form.azure_endpoint.$error"
                :disabled="
                  form.enable_single_sign_on_azure_active_directory == 0
                "
                v-model="$v.form.azure_endpoint.$model"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="title-tabs">Bundle Product Recommendation</div>
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <b-col>
              <InputText
                textFloat="Bundle Calculation Period (0-365)"
                name="bundle_culculation"
                placeholder=""
                type="number"
                v-model="form.bundle_calculation_day"
              />
            </b-col>
          </div></div
      ></b-tab>
      <b-tab title="RFM">
        <div class="bg-white p-2 p-lg-3 text-black">
          <div class="row">
            <div class="col-sm-6 col-12">
              <InputText
                textFloat="Calculate Period (Day)"
                name="calculate_period"
                placeholder=""
                :v="$v.form.calculate_period"
                :isValidate="$v.form.calculate_period.$error"
                type="number"
                oninput="if( this.value.length > 4 )  this.value = this.value.slice(0,4)"
                isRequired
                v-model="form.calculate_period"
              />
              <b-button variant="custom-link" @click="handleCalPeriod()">
                Recalculate
              </b-button>
            </div>
            <div class="col-md-6 col-12 mb-2 mb-md-0">
              <div class="color-primary font-bold">Calculate period (Day)</div>
              <div class="text-black">
                The Recency-Frequency-Monetary (RFM) and Repuchase Purchase
                analysis will be performed by determining the time interval
                between the present moment and a specific past date
                <br />
                The Recency-Frequency-Monetary (RFM) analysis is conducted on a
                daily basis
              </div>
            </div>
          </div>
          <hr />
          <b-tabs
            v-model="tabIndex"
            content-class="px-4 mt-3"
            v-if="isRFMLoading"
          >
            <b-tab v-for="(tab, index) of RFMTab" :key="tab" :title="tab">
              <b-row
                v-for="(scale, n) of rfmScale[index].rfm_range_level"
                :key="scale.rate_scale + '-' + index"
              >
                <b-col cols="4" md="3" lg="2">
                  <InputText
                    :textFloat="n == 0 ? 'Score' : ''"
                    :name="'score' + n + index"
                    placeholder=""
                    type="number"
                    :disabled="true"
                    v-model="scale.rate_scale"
                  />
                </b-col>
                <b-col cols="4" md="3" lg="2">
                  <InputText
                    :textFloat="n == 0 ? 'From' : ''"
                    :name="'from' + n + index"
                    placeholder=""
                    type="number"
                    :useDot="false"
                    v-model="scale.st_range"
                  />
                </b-col>
                <b-col cols="4" md="3" lg="2">
                  <InputText
                    :textFloat="n == 0 ? 'To' : ''"
                    :name="'to' + n + index"
                    placeholder=""
                    type="number"
                    :useDot="false"
                    v-model="scale.ed_range"
                  />
                </b-col>
              </b-row>
              <hr />
              <label class="main-label">X Axis</label>

              <b-row
                v-for="(range, n) of rfmScale[index].rfm_range_rate"
                :key="'range-' + range.rate_scale + '-' + index"
              >
                <b-col cols="4" md="3" lg="2">
                  <InputText
                    :textFloat="n == 0 ? 'Order' : ''"
                    :name="'score' + n + index"
                    placeholder=""
                    type="number"
                    :disabled="true"
                    :useDot="false"
                    v-model="range.rate_scale"
                  />
                </b-col>
                <b-col cols="4" md="3" lg="2">
                  <InputText
                    :textFloat="n == 0 ? 'From' : ''"
                    :name="'from' + n + index"
                    placeholder=""
                    type="number"
                    :useDot="false"
                    v-model="range.st_range"
                  />
                </b-col>
                <b-col cols="4" md="3" lg="2">
                  <InputText
                    :textFloat="n == 0 ? 'To' : ''"
                    :name="'to' + n + index"
                    placeholder=""
                    type="number"
                    :useDot="false"
                    v-model="range.ed_range"
                  />
                </b-col>
                <b-col v-if="n != 0">
                  <b-button
                    variant="link"
                    @click="deleteRangeScale(n, index)"
                    class="text-gray"
                  >
                    <font-awesome-icon icon="trash-alt" class="ft-14 ml-2" />
                  </b-button>
                </b-col>
              </b-row>
              <b-button
                v-if="rfmScale[index].rfm_range_rate.length < 20"
                variant="custom-link"
                @click="addRangeScale(index)"
                >Add Graph</b-button
              >
            </b-tab>
          </b-tabs>
        </div>
      </b-tab>
    </b-tabs>

    <FooterAction @submit="submit" routePath="/setting" />
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
const isValidUrl = (string, params, form) => {
  try {
    if (params == "policy" && form.is_custom_privacy_link == 0) return true;
    if (params == "term" && form.is_term_and_con_url == 0) return true;
    if (params == "right" && form.is_right_request_url == 0) return true;
    if (
      params == "azure" &&
      form.enable_single_sign_on_azure_active_directory == 0
    )
      return true;

    new URL(string);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};
export default {
  data() {
    return {
      RFMTab: ["Recency", "Frequency", "Montery", "Return Purchase"],
      tabIndex: 0,
      form: {
        customer_user_coupon_product: "",
        customer_user_coupon_sale: "",
        invoice_no_customer_coupon: "",
        x_day_prospect_inactive: "",
        x_day_first_lapse: "",
        x_day_active_lapse: "",
        x_day_lapse_inactive: "",
        calculate_period: "",
        is_custom_privacy_link: "0",
        consent_url_link: "",
        is_term_and_con_url: "0",
        term_and_con_url: "",
        is_right_request_url: "0",
        right_request_url: "",
        point_system: "0",
        enable_single_sign_on_azure_active_directory: "0",
        client_id: "",
        azure_endpoint: "",
        bundle_calculation_day: 0,
      },
      rfmScale: [],
      isRFMLoading: false,
    };
  },
  validations: {
    form: {
      customer_user_coupon_product: { required },
      customer_user_coupon_sale: { required },
      invoice_no_customer_coupon: { required },
      x_day_prospect_inactive: { required },
      x_day_first_lapse: { required },
      x_day_active_lapse: { required },
      x_day_lapse_inactive: { required },
      calculate_period: { required },
      consent_url_link: {
        isValidUrl: (val, form) => isValidUrl(val, "policy", form),
        required: requiredIf(function () {
          return this.form.is_custom_privacy_link == 1;
        }),
      },
      term_and_con_url: {
        isValidUrl: (val, form) => isValidUrl(val, "term", form),
        required: requiredIf(function () {
          return this.form.is_term_and_con_url == 1;
        }),
      },
      right_request_url: {
        isValidUrl: (val, form) => isValidUrl(val, "right", form),
        required: requiredIf(function () {
          return this.form.is_right_request_url == 1;
        }),
      },
      client_id: {
        required: requiredIf(function () {
          return this.form.enable_single_sign_on_azure_active_directory == 1;
        }),
      },
      azure_endpoint: {
        isValidUrl: (val, form) => isValidUrl(val, "azure", form),
        required: requiredIf(function () {
          return this.form.enable_single_sign_on_azure_active_directory == 1;
        }),
      },
    },
  },
  created() {
    this.getList();
  },
  methods: {
    checkText(e) {
      let ew = e.key;
      if (!/[A-Za-z0-9/ ]/.test(ew)) return e.preventDefault();
    },
    async getList() {
      this.isRFMLoading = false;
      const response = await this.$Axios(
        `${process.env.VUE_APP_API}/Coupon/get_coupon_setting`
      );
      this.form = response.data.detail;
      const resp = await this.$Axios(
        `${process.env.VUE_APP_REPORT_API}/dashboard/RFM/rate_scale`
      );

      for (const n of resp.data.detail) {
        var i = 0;
        for (const m of n.rfm_range_level) {
          i++;

          if (m.ed_range == 0 && n.rfm_range_level.length == i) {
            m.ed_range = "";
          }
        }
      }
      this.rfmScale = resp.data.detail;
      this.isRFMLoading = true;
    },
    async handleCalPeriod() {
      this.isRFMLoading = false;
      const resp = await this.$Axios(
        `${process.env.VUE_APP_REPORT_API}/dashboard/RFM/calculate_rate_scale/${this.form.calculate_period}`
      );
      for (const n of resp.data.detail) {
        var i = 0;
        for (const m of n.rfm_range_level) {
          i++;

          if (m.ed_range == 0 && n.rfm_range_level.length == i) {
            m.ed_range = "";
          }
        }
      }
      this.rfmScale = resp.data.detail;
      this.isRFMLoading = true;
    },
    clearField(val, keys) {
      if (val == 0) {
        keys.forEach((key) => {
          this.form[key] = "";
        });
      }
    },
    deleteRangeScale(n, index) {
      this.rfmScale[index].rfm_range_rate.splice(n, 1);
      this.rfmScale[index].rfm_range_rate = this.rfmScale[
        index
      ].rfm_range_rate.map((el, a) => {
        return { ...el, rate_scale: a + 1 };
      });
    },
    addRangeScale(index) {
      this.rfmScale[index].rfm_range_rate.push({
        rate_scale: this.rfmScale[index].rfm_range_rate.length + 1,
        st_range:
          this.rfmScale[index].rfm_range_rate[
            this.rfmScale[index].rfm_range_rate.length - 1
          ].ed_range + 0.1,
        ed_range: null,
      });
    },
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$EventBus.$emit("showLoading");
      let rfmPayload = JSON.parse(JSON.stringify(this.rfmScale));
      for (const n of rfmPayload) {
        var i = 0;
        for (const m of n.rfm_range_level) {
          i++;

          if (!m.ed_range && n.rfm_range_level.length == i) {
            m.ed_range = 0;
          }
        }
      }
      const rfmRes = await this.$Axios.post(
        `${process.env.VUE_APP_REPORT_API}/dashboard/RFM/new_rate_scale`,
        rfmPayload
      );

      const response = await this.$Axios.put(
        `${process.env.VUE_APP_API}/Coupon/setting`,
        this.form
      );

      this.$EventBus.$emit("hideLoading");
      if (response.data.result) {
        this.successAlert().then(() => {
          this.$router.push(`/setting`);
        });
      } else {
        this.errorAlert(response.data.message);
      }
    },
  },
};
</script>

<style>
.break-ele {
  flex-basis: 100%;
  height: 0;
}
.font-bold {
  font-weight: bold;
}
</style>
